
#co2-calculator-root {  
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

@tailwind base;
@tailwind utilities;
@tailwind components;

@layer components {
  /* Note: Prefix all selectors for rc-slider with #co2-calculator-root
     to increase specificity and allow overwriting the lib styles */
  #co2-calculator-root .rc-slider-track {
    @apply bg-green-600;
  }

  body {
    @apply font-copy;
    @apply m-0;
  }

  h1, h2, h3 {
    @apply font-display;
  }
}

/* disable scrollbar in firefox and ie */
* {
  overflow: -moz-scrollbars-none;
}
* {
  -ms-overflow-style: none;
}

/*********************************/
/** PRINT STYLES                **/
/*********************************/
@page {
  size: A4;
  /* margin: 33mm 17mm 22mm 17mm; */
}

@media print {
  .print\:page-break-before-always {  
    page-break-before: always;
  }
  .print\:page-break-after-always {  
    page-break-after: always;
  }

  .print\:break-inside-avoid {
    break-inside: avoid;
  }
  /* h1, h2, h3 {
      page-break-before: always;
  }
  h1 + h2, h1 + h3, h2 + h3 {
      page-break-before: avoid;
  } */
}
